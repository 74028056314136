<template>
  <v-dialog v-model="visible" max-width="600">
    <v-card>  
      <v-card-title class="display-1">
        Price Calculator
        <v-spacer />
        <ToggleButtonInput 
          v-model="request.currency" 
          class="float-right flex-grow-0"
          label="Currency" 
          small 
          :items="siteData.currencies.map(x => ({ text: x, type: x}))" 
          />
      </v-card-title>
      <v-card-text class="mt-4 pb-0">
        This calculator will help you calculate the price for your event in a few easy steps.
      </v-card-text>
      <v-card-text>

        <ToggleButtonInput 
          v-model="request.mode" 
          :items="[ { text: 'One-time Event', type: 'EVENT'}, { text: 'Ongoing or multiple events', type: 'SUBSCRIPTION' } ]" 
          />

        <ToggleButtonInput 
          v-model="request.license" 
          label="Plan" 
          :items="[ { text: 'Standard', type: 'BASIC'}, { text: 'PRO', type: 'PRO' }, { text: 'Enterprise', type: 'ENTERPRISE' } ]" 
          />

        <ToggleButtonInput 
          v-model="request.support" 
          label="Support plan" 
          :items="[ { text: 'Self-service', type: 'SELF_SERVICE'}, { text: 'Full-Service', type: 'FULL_SERVICE' } ]" 
          />

        <v-subheader class="ps-0">Number of users</v-subheader>
        <v-slider 
          v-model="request.amount"
          min="10"
          max="1000"
          thumb-label
          step="10"
          />

        <v-row v-if="request.mode == 'EVENT'">
          <v-col cols="12" sm="4">
            <BigButton 
              elevated
              title="One-time Setup" 
              :text="`${request.currency} ${$options.filters.int(oneTimeFee)}`" 
              />
          </v-col>
          <v-col cols="12" sm="4">
            <BigButton 
              elevated 
              title="User Fee" 
              :text="`${request.currency} ${$options.filters.int(totalUserFee)}`" 
              />
          </v-col>
          <v-col cols="12" sm="4">
            <BigButton 
              elevated 
              title="Total Price" 
              :text="`${request.currency} ${$options.filters.int(totalPrice)}`" 
              />
          </v-col>
        </v-row>
        <v-row v-if="request.mode == 'SUBSCRIPTION'">
          <v-col cols="12" sm="4">
            <BigButton 
              elevated
              title="One-time Setup" 
              :text="`${request.currency} ${$options.filters.int(oneTimeFee)}`" 
              />
          </v-col>
          <v-col cols="12" sm="4">
            <BigButton 
              elevated 
              title="Per Month" 
              :text="`${request.currency} ${$options.filters.int(totalMonthlyUserFee)}`" 
              />
          </v-col>
          <v-col cols="12" sm="4">
            <BigButton 
              elevated 
              title="Per Year" 
              :text="`${request.currency} ${$options.filters.int(totalAnnualUserFee)}`" 
              />
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="visible = false">Close</v-btn>
      </v-card-actions>      
    </v-card>
  </v-dialog>
</template>

<script>
import ToggleButtonInput from "@/components/ToggleButtonInput";
import BigButton from "@/components/BigButton.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "PriceCalculatorDialog",
  props: {
  },
  components: {
    ToggleButtonInput,
    BigButton,
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
      visible: false,
      products: siteData.products,
      currencies: siteData.currencies,
      request: {
        mode: 'EVENT',
        currency: 'USD',
        license: 'PRO',
        support: 'SELF_SERVICE',
        amount: 100,
      }
    };
  },
  mounted() {
  },
  methods: {
    open() {
      this.visible = true;
    },
  },
  computed: {
    selectedProduct() {
      if (!this.request.currency || !this.request.license || !this.request.mode) {
        return null;
      }
      if (this.request.mode == 'EVENT') 
        return this.products.find(x=>x.key==`product-event-${this.request.license.toLowerCase()}`);
      if (this.request.mode == 'SUBSCRIPTION') 
        return this.products.find(x=>x.key==`subscription-month-${this.request.license.toLowerCase()}`);
    },
    oneTimeFee() {
      if (this.request.support == 'SELF_SERVICE') {
        return 0;
      }
      return this.products.find(x=>x.key=='product-full-service').prices[this.request.currency];
    },
    totalUserFee() {
      if (!this.selectedProduct) {
        return null;
      }
      return this.selectedProduct.prices[this.request.currency] * this.request.amount;
    },
    totalMonthlyUserFee() {
      return this.totalUserFee;
    },
    totalAnnualUserFee() {
      return this.totalMonthlyUserFee * 12;
    },
    totalPrice() {
      if (!this.selectedProduct) {
        return null;
      }
      return this.oneTimeFee + this.totalUserFee;
    },
  },
};
</script>

<style scoped>
</style>